import axios from "axios";
import router from "../../router";

export default {
  namespaced: true,
  state: {
    loggedIn: false,
    idActionsRol: [],
    modules: [],
    deniedCompany: {
      title: "Error!",
      html: "La empresa no tiene permiso a esa sección",
      timer: 15000,
      icon: "error",
      confirmButtonColor: "#d60400",
    },
    deniedUser: {
      title: "Error!",
      html: "Usted no tiene permiso a esa sección",
      timer: 15000,
      icon: "error",
      confirmButtonColor: "#d60400",
    },
  },
  mutations: {
    setLogedIn(state, payload) {
      state.loggedIn = payload;
    },

    setActionsRol(state) {
      state.idActionsRol = [];
      if (localStorage.getItem("user") != "") {
        let user = JSON.parse(localStorage.getItem("user"));
        state.idActionsRol.push("HOME");
        for (let permission of user.permission) {
          let action = state.idActionsRol.find((e) => e === permission);
          if (typeof action == "undefined") {
            state.idActionsRol.push(permission);
          }
        }
      }
    },

    setAvailableModules(state) {
      if (localStorage.getItem("user") != "") {
        let user = JSON.parse(localStorage.getItem("user"));
        state.modules = user.modules;
      }
    },
  },
  actions: {
    errorRequest({ commit, state, dispatch, rootState }, payload) {
      console.log("entró en el errorRequest");

      rootState.master.alertas = "";
      let errorValidacion = false;

      errorValidacion = payload.data.status === 422;

      if (errorValidacion) {
        // ERROR DE VALIDACION DEL REQUEST

        let messages = payload.data.response.data.validation_messages;

        console.log(messages);
        Object.keys(payload.data.response.data.validation_messages).forEach(
          (item) => {
            let msg = messages[item];
            console.log(typeof msg);

            if (typeof msg == "object") {
              Object.keys(msg).forEach((item2) => {
                rootState.master.alertas += msg[item2] + "<br />";
              });
            } else {
              for (let i = 0; i < msg.length; i++) {
                rootState.master.alertas += msg[i] + "<br />";
              }
            }
          }
        );
      } else if (payload.data.status === 500) {
        // MAYORMENTE ERROR DE PROGRAMACIÓN
        if (typeof payload.data.datos != "undefined") {
          let i = 1;
          Object.keys(payload.data.datos).forEach((item) => {
            let msg = payload.data.datos[item];
            if (i < 5) rootState.master.alertas += item + " " + msg + "<br />";
            i++;
          });
        } else {
          // console.log(payload.data.response.data);
          rootState.master.alertas += payload.data.response.data.detail;
        }
      } else {
        rootState.master.alertas =
          "Ha ocurrido un error inesperado, intente nuevamente";
      }

      /* if(typeof payload.data.btn == "undefined" && state.loadingBtn)
                commit('master/setLoadingBtn',false, { root: true })
            else if(payload.data.btn===2)
                commit('master/setLoadingBtn2',false, { root: true }) */

      let error500 = !(
        typeof payload.data != "undefined" && payload.data.status === 500
      );
      //error500 = payload.data.response.data.msg;
      let data = {
        param: {
          title: "Error!",
          html: rootState.master.alertas,
          icon: "error",
          toast: error500,
          timer: !error500 ? 25000 : rootState.master.sweetAlert.timer,
          position: rootState.master.sweetAlert.position,
          confirmButtonColor: "#d60400",
        },
      };
      dispatch("master/alertNotification", data, { root: true });
    },

    login({ state, commit, dispatch, rootState }, payload) {
      return new Promise((resolve, reject) => {
        commit("master/setLoadingBtn", true, { root: true });
        commit("master/setUrl", "api/login", { root: true });

        axios
          .post(rootState.master.url, {
            user_name: payload.user,
            user_pass: payload.password,
            grant_type: "client_credentials",
          })
          .then((res) => {
            resolve(res);

            if (
              res.statusText === "OK" &&
              res.status === 200 &&
              res.data.access_token
            ) {
              let token = res.data.access_token;
              localStorage.setItem("token", token);

              commit("master/setUrl", "api/datos-usuario", { root: true });

              axios
                .post(rootState.master.url, {
                  user_name: payload.user,
                  system: "sistema_de_carga",
                  //dataBase: payload.company,
                  comment: "Ha iniciado sesión ",
                })
                .then((res) => {
                  //res.data.user.pass = payload.password
                  let userString = JSON.stringify(res.data.user);
                  localStorage.setItem("user", userString);

                  //console.log(userString)
                  dispatch("checkSessionStatus");
                  //commit('master/setMenu',state.general.menu)
                  router.push("/");

                  dispatch(
                    "master/alertNotification",
                    {
                      param: {
                        html: "Bienvenido " + payload.user,
                      },
                    },
                    { root: true }
                  );
                });
            }
          })
          .catch((err) => {
            reject(err);

            if (!state.loadingBtn)
              commit("master/setLoadingBtn", null, { root: true });

            let response = err.response;

            if (!response.data.login) {
              dispatch(
                "master/alertNotification",
                {
                  param: {
                    title: "Error",
                    icon: "error",
                    html: "El usuario o contraseña son incorrectos",
                    confirmButtonColor: "#d60400",
                  },
                },
                { root: true }
              );
            } else {
              dispatch(
                "errorRequest",
                {
                  data: {
                    datos: response.data.errors,
                    status: response.status,
                  },
                },
                { root: true }
              );
            }
          });
      });
    },

    checkSessionStatus({ commit, dispatch }) {
      let existToken =
        localStorage.getItem("token") != null &&
        localStorage.getItem("token") !== undefined &&
        typeof localStorage.getItem("token") !== "undefined";

      if (existToken) {
        commit("master/setToken", localStorage.getItem("token"), {
          root: true,
        });

        if (localStorage.getItem("user") != null) {
          commit("master/setUser", JSON.parse(localStorage.getItem("user")), {
            root: true,
          });
          commit("setActionsRol");
          //commit('setAvailableModules')
          commit("setLogedIn", true);
        }
      }
    },

    recuperar({ state, commit, dispatch, rootState }, payload) {
      return new Promise((resolve, reject) => {
        commit("master/setLoadingBtn", true, { root: true });
        commit("master/setUrl", "api/request-password-change", { root: true });
        //localStorage.setItem('tenantId',payload.company)

        axios
          .post(rootState.master.url, {
            userName: payload.userName,
          })
          .then((res) => {
            resolve(res);

            if (
              res.statusText === "OK" &&
              res.status === 200 &&
              res.data.access_token
            ) {
            }
          })
          .catch((err) => {
            reject(err);

            if (!state.loadingBtn)
              commit("master/setLoadingBtn", null, { root: true });

            let response = err.response;

            if (!response.data.login) {
              dispatch(
                "master/alertNotification",
                {
                  param: {
                    title: "Error",
                    icon: "error",
                    html: "El usuario o contraseña son incorrectos",
                    confirmButtonColor: "#d60400",
                  },
                },
                { root: true }
              );
            } else {
              dispatch(
                "errorRequest",
                {
                  data: {
                    datos: response.data.errors,
                    status: response.status,
                  },
                },
                { root: true }
              );
            }
          });
      });
    },

    cambiarPassword({ state, commit, dispatch, rootState }, payload) {
      return new Promise((resolve, reject) => {
        commit("master/setLoadingBtn", true, { root: true });
        commit("master/setUrl", "api/change-password", { root: true });
        //localStorage.setItem('tenantId',payload.company)

        axios
          .post(rootState.master.url, {
            token: payload.token,
            password: payload.password,
          })
          .then((res) => {
            resolve(res);

            if (
              res.statusText === "OK" &&
              res.status === 200 &&
              res.data.access_token
            ) {
            }
          })
          .catch((err) => {
            reject(err);

            if (!state.loadingBtn)
              commit("master/setLoadingBtn", null, { root: true });

            let response = err.response;

            if (!response.data.login) {
              dispatch(
                "master/alertNotification",
                {
                  param: {
                    title: "Error",
                    icon: "error",
                    html: "El usuario o contraseña son incorrectos",
                    confirmButtonColor: "#d60400",
                  },
                },
                { root: true }
              );
            } else {
              dispatch(
                "errorRequest",
                {
                  data: {
                    datos: response.data.errors,
                    status: response.status,
                  },
                },
                { root: true }
              );
            }
          });
      });
    },

    /**
          getModules({state,commit,dispatch,rootState}){
      
            commit('master/setUrl',`user-laboratorio/${rootState.master.tenantId}`,{ root: true })
            dispatch('master/requestApi',{
                method: 'PUT',
                data:{}
            },{ root: true })
            .then(res=>{
                let user = JSON.parse(localStorage.getItem('user'))
                user.modules = res.data.modules
                user.permission = res.data.permission
                localStorage.setItem('user',JSON.stringify(user))
                commit('setActionsRol')
                commit('setAvailableModules')
            }) 

        } **/
  },
  getters: {
    // GETTERS ACCESS IS LOGGED IN
    getIsLoggedIn(state) {
      return state.loggedIn;
    },

    // GETTERS ACCESS COMPANY APPLICATION

    store(state) {
      return state.modules.find((e) => e.module == "BODEGA");
    },

    purchases(state) {
      return state.modules.find((e) => e.module == "COMPRAS");
    },

    admin(state) {
      return state.modules.find((e) => e.module == "ADMIN");
    },

    // GETTERS ACCESS USER PERMISSIONS
    inicio(state) {
      return state.idActionsRol.find((e) => e === "HOME");
    },
    ingresoReservasFincas(state) {
      return state.idActionsRol.find((e) => e === 2);
    },

    reservasCoordFincas(state) {
      return state.idActionsRol.find((e) => e === 3);
    },

    trackingClientes(state) {
      return state.idActionsRol.find((e) => e === 4);
    },

    inventarioGuias(state) {
      return state.idActionsRol.find((e) => e === 1);
    },
    listaFincas(state) {
      return state.idActionsRol.find((e) => e === 12);
    },
    agentesCarga(state) {
      return state.idActionsRol.find((e) => e === 11);
    },
    listaDaes(state) {
      return state.idActionsRol.find((e) => e === 5);
    },
    listaDaesGeneral(state) {
      return state.idActionsRol.find((e) => e === 14);
    },
    historialEmbarques(state) {
      return state.idActionsRol.find((e) => e === 10);
    },
    listaMawbCoord(state) {
      return state.idActionsRol.find((e) => e === 13);
    },
    listaUsuarios(state) {
      return state.idActionsRol.find((e) => e === 15);
    },
    listaGuiasAereas(state) {
      return state.idActionsRol.find((e) => e === 17);
    },
    ingresoReservasAdmin(state) {
      return state.idActionsRol.find((e) => e === 20);
    },
    confirmarReservas(state) {
      return state.idActionsRol.find((e) => e === 19);
    },
    reservasConsolidado(state) {
      return state.idActionsRol.find((e) => e === 18);
    },
    permisos(state) {
      return state.idActionsRol.find((e) => e === 16);
    },
    privilegios(state) {
      return state.idActionsRol.find((e) => e === 16);
    },

    listaShippers(state) {
      return state.idActionsRol.find((e) => e === 35);
    },

    listaNotifies(state) {
      return state.idActionsRol.find((e) => e === 36);
    },

    listaAerolineas(state) {
      return state.idActionsRol.find((e) => e === 34);
    },

    listaAeropuertos(state) {
      return state.idActionsRol.find((e) => e === 38);
    },

    listaPaises(state) {
      return state.idActionsRol.find((e) => e === 39);
    },

    listaCiudades(state) {
      return state.idActionsRol.find((e) => e === 11);
    },

    listaClientes(state) {
      return state.idActionsRol.find((e) => e === 37);
    },

    listaTransportistas(state) {
      return state.idActionsRol.find((e) => e === 54);
    },

    asignarTransportistas(state) {
      return state.idActionsRol.find((e) => e === 53);
    },

    listaContactos(state) {
      return state.idActionsRol.find((e) => e === 55);
    },

    formContacto(state) {
      return state.idActionsRol.find((e) => e === 56);
    },

    cargaBodega(state) {
      return state.idActionsRol.find((e) => e === 57);
    },

    listaPod(state) {
      return state.idActionsRol.find((e) => e === 58);
    },

    formPod(state) {
      return state.idActionsRol.find((e) => e === 59);
    },

    dimensionesCajas(state) {
      return state.idActionsRol.find((e) => e === 60);
    },

    participantes(state) {
      return state.idActionsRol.find((e) => e === 58);
    },

    guiasMaster(state) {
      return state.idActionsRol.find((e) => e === 58);
    },

    guiasHijas(state) {
      return state.idActionsRol.find((e) => e === 58);
    },

    resumenTransp(state) {
      return state.idActionsRol.find((e) => e === 59);
    },

    guiasMadresMiami(state) {
      return state.idActionsRol.find((e) => e === 59);
    },

    guiasHijasMiami(state) {
      return state.idActionsRol.find((e) => e === 59);
    },
    hojaCoordinacion(state) {
      return state.idActionsRol.find((e) => e === 33);
    },
    cambiarGuiaCoordinacion(state) {
      return state.idActionsRol.find((e) => e === 32);
    },
    cambiarCargaGuia(state) {
      return state.idActionsRol.find((e) => e === 31);
    },
    reporteGuiasMasterAerolinea(state) {
      return state.idActionsRol.find((e) => e === 65);
    },
    transmisionEDI(state) {
      return state.idActionsRol.find((e) => e === 66);
    },
    mensajesEDI(state) {
      return state.idActionsRol.find((e) => e === 67);
    },
    descargaOPD(state) {
      return state.idActionsRol.find((e) => e === 68);
    },
    descargarSBDW(state) {
      return state.idActionsRol.find((e) => e === 69);
    },
    envioPrealertas(state) {
      return state.idActionsRol.find((e) => e === 70);
    },
    accesosFincas(state) {
      return state.idActionsRol.find((e) => e === 71);
    },
    coordiacionGuiaAerea(state) {
      return state.idActionsRol.find((e) => e === 72);
    },
    guiaMasterForm(state) {
      return state.idActionsRol.find((e) => e === 73);
    },
  },
};
